import { Button, Form, Modal, notification, Typography } from "antd";
import React, { useState, useEffect } from "react";
import "./SomethingWrong.scss";

const SomethingWrong: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    return (
      <div style={{height:"100%"}}>
        {children && (
          <div style={{height:"100%"}}>
            {children}
          </div>
        )}
      </div>
    );
  };

 export default SomethingWrong;
