import {
  Button,
  Col,
  Collapse,
  Divider,
  List,
  Modal,
  notification,
  Popconfirm,
  Radio,
  Row,
  Space,
  Spin,
  Typography,
  Upload,
  UploadProps,
} from "antd";
import CheckableTag from "antd/es/tag/CheckableTag";
import React, { useCallback, useEffect, useState } from "react";
import FormIcons from "../../components/FormIcons/FormIcons";
import FormSelect from "../../components/FormSelect/FormSelect";
import API_SERVICE from "../../services/api-service";
import {
  downloadDocument,
  isRazorPay,
  numberWithCommas,
  updateDropOfStage,
} from "../../services/Utility";
import "./application-details.scss";
import "../../styles/theme-colors.scss";
import dayjs from "dayjs";
import Document from "../upload-documents/Document";

const { Title, Text } = Typography;
const { Panel } = Collapse;

interface ILoanDetails {
  leadDetail: any;
  extraData: any;
  componentParameters: any;
  finalFormData: any;
  setShowList: Function;
  isMobile: boolean;
  isV2: boolean;
  loanType: String;
  addGTag: Function;
  complementorUserId?:any;
}

const ApplicationDetails: React.FunctionComponent<ILoanDetails> = (
  props: ILoanDetails
) => {
  const {
    leadDetail,
    extraData,
    componentParameters,
    setShowList,
    isMobile,
    isV2,
    loanType,
    addGTag,
    finalFormData,
    complementorUserId,
  } = props;

  const {address, caseDetails} = leadDetail;
  const { offerDetails, applicationDetails, personalDetails, multilenderOffer } = finalFormData;
  const contactDetails = personalDetails ?? leadDetail?.contactDetails;

  const city = finalFormData?.city ?? personalDetails?.city ?? address?.city;
  const pinCode = finalFormData?.pincode ?? personalDetails?.pinCode ?? address?.pinCode;

  const monthlyEmi = leadDetail?.monthlyEmi ?? finalFormData?.monthlyEmi;
  const businessTurnOver = leadDetail?.businessTurnOver ?? finalFormData?.latestYearTurnover;
  const businessProfile = leadDetail?.businessProfile ?? finalFormData?.businessProfile;
  const businessVintage = finalFormData?.vintage ?? leadDetail?.businessVintage;
  const applicationSubTypeKey = leadDetail?.applicationSubTypeKey ?? finalFormData?.applicantSubType;
  const applicationType =
    applicationDetails?.applicationType ?? leadDetail?.applicationType ?? finalFormData?.applicantType;
  const applicationSubType = applicationDetails?.applicationSubType ?? leadDetail?.applicationSubType;
  const currentEmis = applicationDetails?.currentEmis ?? finalFormData?.monthlyEmi ?? caseDetails?.loanDetails?.currentEmis;
  const monthlyInHandSalary =  applicationDetails?.monthlyInHandSalary ?? finalFormData?.monthlyInHandSalary ?? caseDetails?.loanDetails?.monthlyInHandSalary;
  const entityOrEmployerName =
    finalFormData?.businessName ?? finalFormData?.employerName ?? applicationDetails?.entityOrEmployerName ?? leadDetail?.entityOrEmployerName;

  const costOfProperty = applicationDetails?.costOfProperty ?? caseDetails?.loanDetails?.costOfProperty;
  const topEligibleLoanAmt = multilenderOffer?.topEligibleLenderLoanOffer;

  const reqLoanAmount = finalFormData?.loanAmount ?? offerDetails?.requestedLoanAmount ?? caseDetails?.loanDetails?.loanAmount;
  const emi = offerDetails?.emi;
  const roi = offerDetails?.roi;
  const tenure = offerDetails?.tenure;
  const gstin = leadDetail?.dsaCaseAdditionalFileds?.decryptionDetails ?? leadDetail?.additionalFileds?.decryptionDetails ?? ""

  const [hierarchyList, setHierarchyList] = useState([]);
  const [documentTypesParent, setDocumentTypesParent] = useState("");
  const [selectedDocumentType, setSelectedDocumentType] = useState(null as any);
  const [fileList, setFileList] = useState([] as any);
  const [uploadedFile, setUploadedFile] = useState(null as any);
  const [imageUrl, setImageUrl] = useState(null as any);
  const [documentList, setDocumentList] = useState([] as any);
  const [documentSubTypeList, setDocumentSubTypeList] = useState([] as any);
  const [selectedDocument, setSelectedDocument] = useState(null as any);
  const [displayDocumentList, setDisplayDocumentList] = useState([] as any);
  const [selectedDocumentName, setSelectedDocumentName] = useState(null as any);
  const [isOpenVerify, setIsOpenVerify] = useState(false);
  const [offerPayload, setOfferPayload] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const [oldDocumentType, setOldDocumentType] = useState(null as any);
  const [isLoading,setIsLoading] = useState(false);
  const dob = (finalFormData?.dateOfBirth?.length ?? 0) > 0 ? finalFormData?.dateOfBirth : finalFormData?.dateOfIncorporation;
  const date =
    leadDetail?.loanType == "Home Loan"
      ? contactDetails?.dateOfBirth ?? ""
      : dayjs(dob ?? contactDetails?.dateOfBirth ?? "").format("DD-MM-YYYY");

  useEffect(() => {
    // if (oldDocumentType && documentList) {
    //   filterDocumentList(oldDocumentType, documentList);
    // }
    setDisplayDocumentList(documentList);
  }, [oldDocumentType, documentList, selectedDocumentType]);

  const [applicantTypes, setApplicantType] = useState([
    { key: "SOLE_PROPRIETOR", value: "Sole Proprietor" },
    { key: "PARTNERSHIP", value: "Partnership" },
    { key: "LLP", value: "Limited Liability Partnership" },
    { key: "HUF", value: "Hindu Undivided Family" },
    { key: "PVT", value: "Private Limited" },
    { key: "LTD", value: "Public Limited" },
    { key: "SELF_EMPLOYED", value: "Self-Employed Professional" },
    { key: "SALARIED", value: "Salaried" },
  ] as any);

  useEffect(() => {
    window.scrollTo(0, 0);
    if(isV2) {
      getJourneyOffer();
    } else {
      getDocumentHierarchyList();
    }
    getDocumentList();
  }, []);

  const getJourneyOffer = () => {
    const params = { 
      caseId: extraData?.processVariables?.dsaCaseId, 
      offerLimit: componentParameters?.viewOffersDescending == "top" ? componentParameters?.number : undefined
    };
    setLoading(true);
    API_SERVICE.getJourneyOffer(params)
      .then(({ data }) => setOfferPayload(data?.payload))
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => setLoading(false));
  };

  const getValueFromEnum = (propertyName: string, key?: string) => {
    let value = {} as any;
    let enumValues = localStorage.getItem("enumValues") as any;
    if (enumValues) {
      enumValues = JSON.parse(enumValues);
      const enumValue = enumValues.find(
        (item) => item.propertyName === propertyName
      );
      value = enumValue?.keyAndDisplayValueList?.find((item: any) => {
        return item?.key === key;
      });
    }
    return value?.value ? value?.value : key ?? "NA";
  };

  const getApplicationType = (applicationTypeKey: any) => {
    const filteredApplicationType = applicantTypes?.filter(
      (item: { key: any }) => item.key === applicationTypeKey
    );
    if ((filteredApplicationType?.length ?? 0) > 0) {
      return filteredApplicationType[0].value;
    }
    return applicationTypeKey;
  };

  const renderOfferDetails = [
    <Text>{`Req Loan Amount: Rs. ${numberWithCommas(reqLoanAmount)}`}</Text>,
    <Text>{`EMI: RS. ${numberWithCommas(emi)}`}</Text>,
    <Text>{`ROI: ${roi}%`}</Text>,
    <Text>{`Tenure: ${tenure} Years`}</Text>,
  ];

  const renderMultiLenderOfferDetails = (data) => {
    const dataOrg = [
        <Text className="multipleOffer">{`Loan Amount: Rs. ${numberWithCommas(data?.calculatedLoanAmount)}`}</Text>,
        <Text>{`EMI: RS. ${numberWithCommas(data?.totalEMI)}`}</Text>,
        <Text>{`ROI: ${data?.interest * 100}%`}</Text>,
        <Text>{`Tenure: ${data?.tenureInMonths / 12} Years`}</Text>,
    ]
    return dataOrg;
  }

  const panDetails = [
    <Text>{`Pan: ${contactDetails?.pan ?? finalFormData?.applicantPan}`}</Text>,
    <Text>{`${
      leadDetail?.loanType == "Home Loan" ||
      applicationType === "INDIVIDUAL" ||
      (applicationSubTypeKey ?? applicationSubType) === "SOLE_PROPRIETOR"
        ? "DOB"
        : "Date of Incorporation"
    }: ${date}`}</Text>,
    <Text>{`City: ${city}`}</Text>,
    <Text>{`Pin Code: ${pinCode}`}</Text>,
  ];

  const gender = contactDetails?.gender ?? finalFormData?.gender;
  const personDetails = [
    <Text>{`Full Name: ${finalFormData?.applicantName ?? contactDetails?.firstName}`}</Text>,
    <Text>{`Mobile Number: ${contactDetails?.mobileNumber}`}</Text>,
    <Text>{`Email: ${contactDetails?.emailId ?? finalFormData?.email ?? ""}`}</Text>,
    gender ? (<Text>{`Gender: ${getValueFromEnum("GENDER", gender )}`}</Text>) : (""),
    ...(applicationType?.toUpperCase() === "INDIVIDUAL" ||
    (applicationSubTypeKey ?? applicationSubType) === "SOLE_PROPRIETOR"
      ? panDetails
      : []),
  ];

  const propertyOwnership = caseDetails?.propertyOwnership ?? finalFormData?.propertyOwnership
  const businessDetails = [
    <Text>
      Application Type:{" "}
      {getApplicationType(applicationSubTypeKey ?? applicationSubType)?.replace(
        "_",
        " "
      )}
    </Text>,
    <Text>{`Business Name: ${entityOrEmployerName}`}</Text>,
    <Text>{`No. of Years In Business: ${businessVintage}`}</Text>,
    <Text>{`Business Profile: ${getValueFromEnum(
      "BUSINESS_PROFILE",
      businessProfile
    )}`}</Text>,
    <Text hidden={!businessTurnOver}>{`Business Turnover (Yearly): Rs. ${numberWithCommas(
      businessTurnOver
    )}`}</Text>,
    <Text hidden={!monthlyEmi}>{`Monthly Obligations (EMIs): Rs. ${numberWithCommas(
      monthlyEmi
    )}`}</Text>,
    <Text>{`Required Loan Amount: Rs. ${numberWithCommas(
      finalFormData?.loanAmount ?? caseDetails?.loanDetails?.loanAmount
    )}`}</Text>,
    <Text hidden={!propertyOwnership}>{`Ownership: ${getValueFromEnum(
      "PROPERTY_OWNERSHIP",
      propertyOwnership
    )}`}</Text>,
    <Text hidden={!gstin?.gstin}>{`GSTIN: ${gstin?.gstin}`}</Text>,
    ...(applicationType?.toUpperCase() === "BUSINESS" &&
    (applicationSubTypeKey ?? applicationSubType) !== "SOLE_PROPRIETOR"
      ? panDetails
      : []),
  ];

  const salaryDetails = [
    <Text>
      Application Type:{" "}
      {getApplicationType(applicationSubTypeKey ?? applicationSubType)?.replace(
        "_",
        " "
      )}
    </Text>,
    <Text>{`Employer Name: ${entityOrEmployerName}`}</Text>,
    <Text>{`Monthly In-hand Salary (INR): ${numberWithCommas(
      monthlyInHandSalary
    )}`}</Text>,
    <Text>{`Required Loan Amount: Rs. ${numberWithCommas(
      reqLoanAmount
    )}`}</Text>,
    <Text>{`Current EMIs: ${numberWithCommas(currentEmis)}`}</Text>,
  ];

  const applicationDetailHL = [
    <Text>
      Application Type:{" "}
      {getApplicationType(applicationSubTypeKey ?? applicationSubType)?.replace(
        "_",
        " "
      )}
    </Text>,
    <Text>{`Current EMIs: ${numberWithCommas(currentEmis)}`}</Text>,
    <Text>{`Employer Name: ${entityOrEmployerName}`}</Text>,
    <Text>{`Monthly In-hand Salary (INR): ${numberWithCommas(
      monthlyInHandSalary
    )}`}</Text>,
    <Text>{`Property Identified: ${costOfProperty > 0 ? "Yes" : "No"}`}</Text>,
    costOfProperty > 0 ? (
      <Text>{`Cost of Property: ${numberWithCommas(costOfProperty)}`}</Text>
    ) : (
      <></>
    ),
  ];

  useEffect(() => {
    // if (selectedDocumentName && documentList) {
    //   filterDocumentList(selectedDocumentName, documentList);
    // }
    setDisplayDocumentList(documentList);
  }, [selectedDocumentName, documentList]);

  const getDocumentHierarchyList = () => {
    let applicationType = "APPLICANT";
    let employmentType = "SELF_EMPLOYED";
    let loanProductId = leadDetail?.loanProductId;

    const params = {
      applicationType,
      loanProductId,
      employmentType,
    };

    API_SERVICE.getDocumentHierarchyList(params)
      .then(({ data }) => {
        if (data) {
          const tmpHierarchyList = data?.payload;
          tmpHierarchyList.push({
            documentCategoryKey: "ZIP",
            documentCategoryName: "Zip",
          });
          setHierarchyList(tmpHierarchyList);
          if ((data?.payload?.length ?? 0) > 0) {
            setOldDocumentType(data?.payload[0]?.documentCategoryKey);
            setDocumentTypesParent(data?.payload[0]?.documentCategoryKey);
            if (documentList?.length > 0) {
              setDisplayDocumentList(documentList);
              // filterDocumentList(
              //   data?.payload[0]?.documentCategoryKey,
              //   documentList
              // );
            }
          }
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const generatDocumentType = (documentSubTypeList: any) => {
    return (
      <div>
        {documentSubTypeList && (
          <FormSelect
            name="documentType"
            value={selectedDocumentType}
            placeholder="Document Type"
            onChange={(e) => setSelectedDocumentType(e)}
            options={documentSubTypeList}
          />
        )}
      </div>
    );
  };

  const onUpload = useCallback((acceptedFiles: any) => {
    if (acceptedFiles?.file?.status === "removed") {
      setUploadedFile(null);
    } else {
      if (acceptedFiles?.file?.size > 9437184) {
        notification.error({ message: "Please attach less than 10MB file" });
        setFileList([]);
        return;
      } else {
        setUploadedFile(acceptedFiles.file);
        uploadDocumentHandler(acceptedFiles.file);
      }
    }
  }, []);

  const uploadProps: UploadProps = {
    accept:
      documentTypesParent === "ZIP"
        ? "application/x-zip-compressed"
        : "image/png, image/jpeg, application/pdf",
    listType: "text",
    // multiple: true,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      if (uploadedFile) {
        setUploadedFile(null);
      }
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
  };

  const uploadDocumentHandler = (file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    API_SERVICE.fileUpload(formData)
      .then(({ data }) => {
        if (data) {
          if (imageUrl || imageUrl === null) {
            setImageUrl(data);
          }
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const uploadDocumentFile = () => {
    addGTag("upload applicant document");
    const allowedAttechmentTypes =
      documentTypesParent === "ZIP"
        ? ["application/x-zip-compressed", "application/zip"]
        : ["application/pdf", "image/jpeg", "image/jpg", "image/png"];

    if (allowedAttechmentTypes.indexOf(uploadedFile?.type) === -1) {
      const msgType =
        documentTypesParent === "ZIP" ? "zip" : "image(jpg/jpeg/png) or pdf";
      notification.error({
        message: `Please attach only ${msgType} file.`,
      });
      return;
    }

    const payload = {
      documentURL: imageUrl,
      documentName: uploadedFile.name,
      documentType: selectedDocumentType,
      documentSubType: selectedDocumentType,
    };
    const documentSetId = leadDetail?.caseDetails?.documentSetId;
    console.log('leadetails', leadDetail);
    API_SERVICE.addDocument(payload, documentSetId)
      .then(({ data }) => {
        if (data) {
          notification.success({ message: data?.message });
          setDocumentTypesParent("");
          setIsOpenVerify(false);
          setSelectedDocumentType(null);
          setFileList([]);
          getDocumentList();
          // filterDocumentList(selectedDocument, documentList);
          setDisplayDocumentList(documentList);
          setUploadedFile(null);
          updateDropOfStage("PROCESS_COMPLETED");
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const getDocumentList = () => {
    const documentSetId = leadDetail?.caseDetails?.documentSetId;
    API_SERVICE.getDocumentList(documentSetId)
      .then(({ data }) => {
        if (data) {
          if (data?.payload?.length > 0) {
            setDocumentList(data.payload);
          } else {
            setDocumentList([]);
          }
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const getType = (type: any) => {
    const filteredEmp = documentSubTypeList?.filter(
      (item: { key: any }) => item.key === type
    );
    if ((filteredEmp?.length ?? 0) > 0) {
      return filteredEmp[0].value;
    }
    return type;
  };

  const handleConnectorTagChange = (i: number, value: string) => {
    setOldDocumentType(value);
    setSelectedDocument(value);
    // filterDocumentList(value, documentList);
  };

  // const filterDocumentList = (value: any, documents: any) => {
  //   if (documentList?.length > 0) {
  //     setDisplayDocumentList(documentList);
  //   }
  //   if (value && documents) {
  //     let data = documents?.filter((doc: any) => {
  //       if (doc.documentType === value?.toUpperCase()?.replace(/ /g, "_")) {
  //         return doc;
  //       } else {
  //         setDisplayDocumentList([]);
  //       }
  //     });
      
  //   }
  // };

  const onDelete = (doc: any) => {
    API_SERVICE.deleteDocument(doc.businessId, doc.businessDocumentId)
      .then(({ data }) => {
        if (data) {
          getDocumentList();
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const goToMainScreen = () => {
    setShowList(true);
  };

  const renderTypeOfDocument = () => {
    return (
      <>
        <div style={{ paddingInline: "5px" }}>
          <div className={isRazorPay(complementorUserId) ? 'razorpay-upload-area' : 'upload-area'}>
            <>
              <Radio.Group    
                className="types-document"
                name="document"
                onChange={(ev) => {
                  if (ev.target.value === "ZIP") {
                    setSelectedDocumentType("ZIP");
                  } else {
                    setSelectedDocumentType(null);
                  }
                  setDocumentTypesParent(ev.target.value);
                }}
                value={documentTypesParent}
              >
                {hierarchyList?.map((documentType: any) => {
                  const value = documentType?.documentCategoryKey;
                  return (
                    <>
                      {value === "ZIP" && (
                        <Divider orientation="center" className={isRazorPay(complementorUserId) ? 'razorpay-divider' : ''}>Or</Divider>
                      )}
                      <Radio value={value}>            
                        {documentType?.documentCategoryName}
                      </Radio>
                      {documentTypesParent === value &&
                        generatDocumentType(documentType?.documentList)}
                    </>
                  );
                })}
              </Radio.Group>
            </>
            <Upload
              className={"upload-wrapper"+(isRazorPay(complementorUserId) ? ' razorpay-upload-wrapper' :'')}
              onChange={onUpload}
              {...uploadProps}
            >
              {isRazorPay(complementorUserId) ? <FormIcons type="addFileWhite" /> :<FormIcons type="addFile" /> }
              {/* <FaFileMedical /> */}
              Upload New Document
            </Upload>
          </div>

          <div
            className={"upload-btns"+(isRazorPay(complementorUserId) ? ' razorPay_btn' : '')}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button          
              style={{ paddingLeft: "42%", paddingRight: "42%" }}
              className=""
              type="primary"
              disabled={!(selectedDocumentType && uploadedFile)}
              onClick={uploadDocumentFile}
            >
              Upload
            </Button>
          </div>
        </div>
      </>
    );
  };

  const renderVerifyModel = () => {
    return (
      <Modal
       className={isRazorPay(complementorUserId) ? 'razorpay-mobileView-modal' : ''}
        title="Type of Document"
        open={isOpenVerify}
        footer={[
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              // paddingInline: "15px",
            }}
          >
            <Button
             className={isRazorPay(complementorUserId) ? 'razorPay_btn_cancel' : ''}
              key="back"
              style={{ paddingInline: "30px", width: "100%" }}
              onClick={() => setIsOpenVerify(false)}
            >
              Cancel
            </Button>
          </div>,
        ]}
        width={300}
        centered
        closable={false}
        onCancel={() => {
          setSelectedDocumentType(null);
          setIsOpenVerify(false);
        }}
      >
        {renderTypeOfDocument()}
      </Modal>
    );
  };

  if (loading) {
    return (
      <div className={"loader-view"}>
        <Spin
          tip="We are processing your request, please wait"
          size="large"
        />
      </div>
    );
  }

  const renderofferV2 = () => {
    const renderOfferDetailsV2 = (offer) => {
      return [
          <Text className="multipleOffer">{`Loan Amount: Rs. ${numberWithCommas(offer?.calculatedLoanAmount)}`}</Text>,
          <Text>{`EMI: RS. ${numberWithCommas(offer?.totalEMI)}`}</Text>,
          <Text>{`ROI: ${offer?.interest}%`}</Text>,
          <Text>{`Tenure: ${offer?.tenureInYears} Years`}</Text>,
      ];
    }

    return (
      <Panel
        header={`Offer Details`}
        key={"0"}
        className={isRazorPay(complementorUserId) ? "razorPay_panel" : ""}
      >
        {offerPayload?.offerDetails?.map((offer, index) => {
          return (
            <>
              <List
                key={`index_${index}`}
                className="custome-list"
                grid={{ gutter: 5, column: 4, xs: 1, sm: 1, md: 1, lg: 3 }}
                dataSource={renderOfferDetailsV2(offer)}
                renderItem={(item) => <List.Item>{item}</List.Item>}
              />
              {offerPayload?.offerDetails?.length !== index + 1 && (
                <Divider style={{ margin: "5px 0px" }} orientation="center" />
              )}
            </>
          );
        })}
      </Panel>
    );
  };

  const renderDocumentV2 = () => {
    return (
      <Panel
        header={`Upload Documents`}
        key={"3"}
        className={
          isRazorPay(complementorUserId) ? "razorPay-upload-docList" : ""
        }
      >
        <div style={{ paddingLeft: "00px", marginTop: "30px" }}>
          <Document
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            leadData={leadDetail}
            complementorUserId={complementorUserId}
          />
        </div>
      </Panel>
    );
  };


  const renderDocumentV1 = () => {
    return (
      <Panel
        header={`Upload Documents`}
        key={"3"}
        className={isRazorPay(complementorUserId) ? "razorPay_panel" : ""}
      >
        <Row>
          {!isMobile && (
            <Col span={11}>
              <div style={{ paddingInline: "20px" }}>
                <Title level={5}>Type of Document</Title>
                {renderTypeOfDocument()}
              </div>
            </Col>
          )}
          {!isMobile && (
            <Col span={1} style={{ display: "flex", justifyContent: "end" }}>
              <Divider
                type="vertical"
                style={{ height: "100%", width: "30px" }}
              />
            </Col>
          )}
          <Col span={isMobile ? 24 : 12}>
            <div>
              <Title level={5} style={{ marginLeft: "00px" }}>
                Uploaded Documents
              </Title>
              <div style={{ paddingLeft: "00px", marginTop: "30px" }}>
                {/* <div className="btn-box">
                  {hierarchyList.map((item: any, i: number) => (
                    <>
                      {item.documentCategoryName ? (
                        <CheckableTag
                          className={
                            "custom-checkable" +
                            (isRazorPay(complementorUserId)
                              ? " razorPay-custom-checkable"
                              : "")
                          }
                          key={item.documentCategoryName}
                          checked={
                            item.documentCategoryKey ===
                            oldDocumentType?.toUpperCase()?.replace(/ /g, "_")
                          }
                          onChange={(checked) => {
                            if (isMobile) {
                              setDocumentTypesParent(item.documentCategoryKey);
                            }
                            handleConnectorTagChange(
                              i,
                              item.documentCategoryName
                            );
                          }}
                        >
                          {item.documentCategoryName}
                        </CheckableTag>
                      ) : null}
                    </>
                  ))}
                </div> */}
              </div>
              <div className="doc-list" style={{ marginLeft: "0px" }}>
                <ul>
                  {displayDocumentList?.length > 0 &&
                    displayDocumentList?.map((doc: any, i: number) => (
                      <li key={i}>
                        <div className="doc-details">
                          {isRazorPay(complementorUserId) ? (
                            <FormIcons type="fileWhite" />
                          ) : (
                            <FormIcons type="file" />
                          )}
                          <span>
                            <span
                              className={
                                isRazorPay(complementorUserId)
                                  ? "razorPay-uploadText"
                                  : ""
                              }
                            >
                              {doc?.documentName}
                              <br />
                            </span>
                            <span
                              className={
                                "type" +
                                (isRazorPay(complementorUserId)
                                  ? " razorPay-uploadText"
                                  : "")
                              }
                            >
                              {getType(
                                doc?.documentSubType ?? doc?.documentType
                              )}
                            </span>
                          </span>
                        </div>
                        <div className="doc-action">
                          <Space>
                            <span
                              onClick={() =>
                                downloadDocument(
                                  doc.businessId,
                                  doc.businessDocumentId
                                )
                              }
                              className="remove"
                            >
                              {isRazorPay(complementorUserId) ? (
                                <FormIcons type="downloadWhite" />
                              ) : (
                                <FormIcons type="download" />
                              )}
                            </span>

                            <Popconfirm
                              placement="topRight"
                              title="Are you sure you want to delete this document?"
                              onConfirm={() => onDelete(doc)}
                              okText="Yes"
                              cancelText="No"
                              className="remove"
                            >
                              <span className="remove">
                                <FormIcons type="delete" />
                              </span>
                            </Popconfirm>
                          </Space>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
              {isMobile && (
                <div
                  className="upload-btns"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    // disabled={!(selectedDocumentType && uploadedFile)}
                    style={{ paddingLeft: "42%", paddingRight: "42%" }}
                    className={
                      isRazorPay(complementorUserId) ? "razorPay_btn" : ""
                    }
                    type="primary"
                    onClick={() => {
                      setIsOpenVerify(true);
                      setSelectedDocumentType(null);
                    }}
                  >
                    Upload
                  </Button>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Panel>
    );
  };

  return (
    <>
      {isMobile && renderVerifyModel()}

      <div
        style={{
          display: "grid",
          paddingInline: isMobile ? "0px" : "50px",
        }}
      >
        <Title level={3} className={"title"+(isRazorPay(complementorUserId) ? ' razorPay_title' : '')} style={{textAlign:"left"}}>
          Application Details
        </Title>
       {isV2 &&
        ( <div style={{background:"#396EF614", marginBottom:"10px"}}>
          <p style={{color:"#0C6EB6", textAlign:"center", marginTop:"10px", fontWeight:500}}>Thank you! We are processing your loan request and will be in touch shortly!</p>
        </div>)
        }
        {/* accordion */}
        <Collapse expandIconPosition="end" defaultActiveKey={["0","3"]}>
          {leadDetail?.loanType == "Home Loan" && (
            <Panel header={`Offer Details`} key={"0"} className={isRazorPay(complementorUserId) ? 'razorPay_panel' : ''}>
              <List
                className="custome-list"
                grid={{ gutter: 5, column: 3, xs: 1, sm: 1, md: 1, lg: 3 }}
                dataSource={renderOfferDetails}
                renderItem={(item) =>  <List.Item>{item}</List.Item>}
              />
            </Panel>
          )}

          {(offerPayload?.offerDetails?.length ?? 0) > 0 ?
            renderofferV2()
           : leadDetail?.loanType == "Business Installment Loan"  && multilenderOffer && topEligibleLoanAmt && (
            <Panel header={`Offer Details`} key={"0"}  className={isRazorPay(complementorUserId) ? 'razorPay_panel' : ''}>
              {topEligibleLoanAmt?.map((items, index) => {
                return (
                  <>
                  <List
                    key={`index_${index}`}
                    className="custome-list"
                    grid={{ gutter: 5, column: 4, xs: 1, sm: 1, md: 1, lg: 3 }}
                    dataSource={renderMultiLenderOfferDetails(items)}
                    renderItem={(item) =>  <List.Item>{item}</List.Item>}
                  />
                  {topEligibleLoanAmt.length !== index + 1 && 
                  <Divider style={{
                    margin: '5px 0px'
                  }} orientation="center" />}
                  </>
                )
              })}
            </Panel>
          )}

          <Panel header={`Personal Details`} key={"1"} className={isRazorPay(complementorUserId) ? 'razorPay_panel' : ''}>
            <List
              className="custome-list"
              grid={{ gutter: 5, column: 3, xs: 1, sm: 1, md: 1, lg: 3 }}
              dataSource={personDetails}
              renderItem={(item) => <List.Item>{item}</List.Item>}
            />
          </Panel>

          <Panel
            header={
              loanType === "PERSONAL_LOAN_ONLY"
                ? "Salary Details"
                : complementorUserId == "CONN4128778344" && leadDetail?.loanType == "Business Installment Loan" ? `Business Details` : `Application Details`
            }
            key={"2"}
            className={isRazorPay(complementorUserId) ? 'razorPay_panel' : ''}
          >
            <List
              className="custome-list"
              grid={{ gutter: 5, column: 3, xs: 1, sm: 1, md: 1, lg: 3 }}
              dataSource={
                loanType === "PERSONAL_LOAN_ONLY"
                  ? salaryDetails
                  : leadDetail?.loanType == "Home Loan"
                  ? applicationDetailHL
                  : businessDetails
              }
              renderItem={(item) => <List.Item>{item}</List.Item>}
            />
          </Panel>
        
          {isV2 ? renderDocumentV2() : renderDocumentV1()}
        </Collapse>
      </div>

      <div
      className="application-footer-div"
        style={{
          display: "flex",
          marginTop: "25px",
          justifyContent: "flex-end",
          marginRight: "50px",
        }}
      >
        <div className="application-footer-msg" style={{ display: 'none'}}>Thank You for sharing your information, we will be in touch shortly.</div>
        <Button
          className={isRazorPay(complementorUserId) ? 'razorPay_formBtn_close' : 'close-button'}
          type="default"
          onClick={goToMainScreen}
        >
          CLOSE
        </Button>
      </div>
    </>
  );
};

export default ApplicationDetails;
